<template>
    <div class="p-2">
        <div class="">
            <span class="text-2xl">Accueil</span>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    }
}
</script>